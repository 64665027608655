// 导入接口
import { BasicApi } from "@/plugins/api.js";

// 主题色
import themeJson from "@/styles/theme/theme.json";

// 缓存主题背景
const bgColor = sessionStorage.getItem("bgColor");

// 当前缓存主题
const activeTheme = sessionStorage.getItem("activeTheme");

const Config = {
  state: {
    themeColor: bgColor ? bgColor : "", // 主题背景
    activeTheme: activeTheme ? activeTheme : "", //当前主题
    isExpand: true, // 展开/收起
    qiniuData: {}, // 七牛云上传参数
    fileUrl: "http://tp.ygddzy.cn/", // 文件域名
  },
  mutations: {
    /** 设置主题色 */
    setActiveTheme(state, data) {
      state.activeTheme = data;
      sessionStorage.setItem("activeTheme", data);
      for (let [key, theme] of Object.entries(themeJson)) {
        if (state.activeTheme === key) {
          let elStyle = document.getElementsByTagName("body")[0].style;
          for (let name in theme) {
            elStyle.setProperty(name, theme[name]);
          }
        }
      }
    },
    /** 设置展开/收起 */
    setIsExpand(state, data) {
      state.isExpand = data;
    },
    /** 设置七牛云上传参数 */
    setQiniuData(state, data) {
      state.qiniuData = data;
    },
    /** 设置七牛云上传路径 */
    setFileUrl(state, data) {
      state.fileUrl = data;
    },
  },
  actions: {
    getQiniuData({ commit }) {
      BasicApi.getQiniuInfo().then((res) => {
        commit("setFileUrl", res.Data.http_domain);
        commit("setQiniuData", res.Data);
      });
    },
  },
};

export default Config;
