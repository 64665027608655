const memberChilds = [
  {
    path: "mactivity",
    name: "Mactivity",
    component: () => import("@/views/member/mactivity.vue"),
    children: [
      {
        path: "activity",
        name: "Activity",
        component: () => import("@/views/member/children/activity.vue"),
      },
    ],
  },
  {
    path: "massociation",
    name: "Massociation",
    component: () => import("@/views/member/massociation.vue"),
    children: [
      {
        path: "association",
        name: "Association",
        component: () => import("@/views/member/children/association.vue"),
      },
    ],
  },
  {
    path: "mmanage",
    name: "Mmanage",
    component: () => import("@/views/member/mmanage.vue"),
    children: [
      {
        path: "manage",
        name: "Manage",
        component: () => import("@/views/member/children/manage.vue"),
      },
      // 积分规则
      {
        path: "integralRule",
        name: "MntegralRule",
        component: () => import("@/views/member/children/integralRule.vue"),
      },
    ],
  },
  {
    path: "mar",
    name: "Mar",
    component: () => import("@/views/member/mar.vue"),
    children: [
      {
        path: "ar",
        name: "Ar",
        component: () => import("@/views/member/children/ar.vue"),
      },
    ],
  },
];
export default memberChilds;
