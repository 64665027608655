const hotelChilds = [
  {
    path: "hprice",
    name: "Hprice",
    component: () => import("@/views/hotel/hprice.vue"),
    children: [
      {
        path: "everyday",
        name: "Everyday",
        component: () => import("@/views/hotel/children/everyday.vue"),
      },
      {
        path: "combo",
        name: "Combo",
        component: () => import("@/views/hotel/children/combo.vue"),
      }
    ]
  },
  {
    path: "htype",
    name: "Hype",
    component: () => import("@/views/hotel/htype.vue"),
    children: [
      {
        path: "tmanager",
        name: "Tmanager",
        component: () => import("@/views/hotel/children/tmanager.vue"),
      }
    ]
  },
  {
    path: "hconfig",
    name: "Hconfig",
    component: () => import("@/views/hotel/hconfig.vue"),
    children: [
      {
        path: "config",
        name: "Config",
        component: () => import("@/views/hotel/children/config.vue"),
      }
    ]
  },
  {
    path: "tag",
    name: "Tag",
    component: () => import("@/views/hotel/tag.vue"),
    children: [
      {
        path: "tagmanage",
        name: "Tagmanage",
        component: () => import("@/views/hotel/children/tagmanage.vue"),
      }
    ]
  },
  // 会员管理
  {
    path: "hmanage",
    name: "Hmanage",
    component: () => import("@/views/hotel/hmanage.vue"),
    children: [
      {
        path: "hmember",
        name: "Hmember",
        component: () => import("@/views/hotel/children/hmember.vue"),
      }
    ]
  },
  // 订单管理
  {
    path: "orderManage",
    name: "OrderManage",
    component: () => import("@/views/hotel/orderManage.vue"),
    children: [
      // 酒店订单
      {
        path: "hotelOrders",
        name: "HotelOrders",
        component: () => import("@/views/hotel/children/hotelOrders.vue"),
      },
      // 套票订单
      {
        path: "assocOrders",
        name: "AssocOrders",
        component: () => import("@/views/hotel/children/assocOrders.vue"),
      }
    ]
  },
  // 套票售票
  {
    path: "packTicketSales",
    name: "PackTicketSales",
    component: () => import("@/views/hotel/packTicketSales.vue"),
    children: [
      {
        path: "ticketing",
        name: "Ticketing",
        component: () => import("@/views/hotel/children/ticketing.vue"),
      }
    ]
  },
];
export default hotelChilds;
