<!-- 退款审核员 -->

<template>
  <div class="checker-dialog">
    <w-dialog ref="dialogRef" title="退款审核员设置" width="50%" top="15vh" :hideFooter="true">
      <div class="left-title">{{ tableTitle }}</div>
      <div class="add-container">
        <div class="right-search">
          <el-select v-model="userId" placeholder="请输入人员姓名、账号查询" filterable remote :remote-method="getUserOptions">
            <el-option v-for="item in options" :key="item.u_uuid" :label="item.u_name" :value="item.u_uuid">
              <span style="float: left">{{ item.u_name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.u_tel
              }}</span>
            </el-option>
          </el-select>
          <div class="add-btn" @click="handleAdd">
            添加
          </div>
        </div>
      </div>
      <el-scrollbar max-height="500px">
        <div class="people-info" v-for="(item, index) in peopleInfo" :key="index">
          <div class="basic-info">
            <span>{{ item.u_name }}</span>
            <span class="department" v-if="item.org.length">
              <template v-if="item.org.length > 3">
                <el-popover effect="dark" placement="top-start" trigger="hover" width="360px"
                  :content="item.org.join('、')">
                  <template #reference>
                    <span class="text-hidden">
                      {{
                        item.org[0] +
                        "、" +
                        item.org[1] +
                        "、" +
                        item.org[2] +
                        "..."
                      }}
                    </span>
                  </template>
                </el-popover>
              </template>
              <template v-else>{{ item.org.join("、") }}</template>
            </span>
            <span class="phone">{{ item.u_tel }}</span>
          </div>
          <div class="delete-action">
            <div class="operator" v-if="operatorInfo && operatorInfo.u_name">
              {{ operatorInfo.u_name
              }}<span v-if="operatorInfo.u_ctime">{{
                operatorInfo.u_ctime
              }}</span>操作
            </div>
            <div class="delete-btn" @click="handleDelete(item)">
              移除
            </div>
          </div>
        </div>
        <div v-if="peopleInfo.length == 0">
          <el-empty description="暂未配置检核人员"></el-empty>
        </div>
      </el-scrollbar>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, computed, watch } from "vue";
  import { BasicApi, HotelApi, ScenicApi, StoreApi, VipApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  export default {
    emits: ["submit"],
    components: {},
    setup() {
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const dialogRef = ref(null);  // 弹框对象
      const userId = ref(null);  // 选择人员id
      const options = ref([]);  // 所以人员数据
      const currentRow = ref('');  // 添加审核员当前行数据
      const currentId = ref('');  // 添加审核员当前行数据id
      const refundType = ref(null);  // 退款类型 scenic_spot：景区  store：门店  hotel：酒店  member：会员
      const tableTitle = ref("");  // 标题
      const peopleInfo = ref({});  // 已添加人员
      const operatorInfo = ref({});  // 操作人员数据
      /**
       * 
       * 打开弹框
       * 
       * **/
      function openDialog(type, row) {
        refundType.value = type;
        currentRow.value = row;  // 添加审核员当前行数据
        currentId.value = type == 'scenic_spot' ? row.sp_id : type == 'store' ? row.pom_id : type == 'hotel' ? row.h_id : type == 'member' ? row.ma_id : '';
        getDetail();
        userId.value = '';
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭弹框
       * 
       * **/
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 获取所有人员数据
       * 
       * **/
      function getUserOptions(query) {
        const lastParmas = {
          resources_id: currentId.value,
          resources_type: refundType.value,
        };
        if (query) {
          lastParmas["keyword"] = query;
        }
        BasicApi.userOptions(lastParmas).then((res) => {
          if (res.Code === 200) {
            options.value = res.Data || [];
          } else {
            let msg = res.Message ? res.Message : "获取所有审核人员数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 获取详情数据
       * 
       * **/
      function getDetail() {
        if (refundType.value == "scenic_spot") {
          // 景区
          ScenicApi.semanageInfo({
            s_id: currentRow.value.s_id,
          }).then((res) => {
            if (res.Code === 200) {
              tableTitle.value = res.Data.s_name;
              peopleInfo.value = res.Data.refundUser;
              operatorInfo.value = res.Data.checkRefundOperationUser
                ? res.Data.checkRefundOperationUser
                : {};
              // operatorInfo.value["check_date"] = res.Data.sp_operation_refund_date
              //   ? res.Data.s_operation_refund_date
              //   : "";
            } else {
              let msg = res.Message ? res.Message : "获取景区详情失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "store") {
          // 门店
          StoreApi.getPomRefundUser({
            pom_id: currentRow.value.pom_id,
          }).then((res) => {
            if (res.Code === 200) {
              tableTitle.value = res.Data.p_name;
              peopleInfo.value = res.Data.refundUser ? res.Data.refundUser : [];
              operatorInfo.value = res.Data.checkRefundOperationUser
                ? res.Data.checkRefundOperationUser
                : {};
            } else {
              let msg = res.Message ? res.Message : "获取门店详情失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "hotel") {
          // 酒店
          HotelApi.getHotelInfo({
            h_id: currentRow.value.h_id,
          }).then((res) => {
            if (res.Code === 200) {
              tableTitle.value = res.Data.p_name;
              peopleInfo.value = res.Data.refundUser;
              operatorInfo.value = res.Data.checkRefundOperationUser
                ? res.Data.checkRefundOperationUser
                : {};
            } else {
              let msg = res.Message ? res.Message : "获取酒店详情失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "member") {
          // 会员
          VipApi.vipActivityDetail({
            ma_id: currentRow.value.ma_id,
          }).then((res) => {
            if (res.Code === 200) {
              tableTitle.value = res.Data.p_name;
              peopleInfo.value = res.Data.refundUser;
              operatorInfo.value = res.Data.checkRefundOperationUser
                ? res.Data.checkRefundOperationUser
                : {};
            } else {
              ElMessage.error(res.Message);
            }
          });
        }
      }
      /**
       * 
       * 添加审核员
       * 
       * **/
      function handleAdd() {
        if (refundType.value == "scenic_spot") {
          // 景区
          ScenicApi.setScenicRefundUser({
            s_id: currentRow.value.s_id,
            u_uuid: userId.value,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("设置审核人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "设置审核人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "store") {
          // 门店
          StoreApi.setPomRefundUser({
            pom_id: currentRow.value.pom_id,
            u_uuid: userId.value,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("设置核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "设置核验人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "hotel") {
          // 酒店
          HotelApi.setHotelRefundUser({
            h_id: currentRow.value.h_id,
            u_uuid: userId.value,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("设置核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "设置核验人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "member") {
          // 会员
          VipApi.setActiveRefundUser({
            ma_id: currentRow.value.ma_id,
            u_uuid: userId.value,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("设置核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "设置核验人员失败！";
              ElMessage.error(msg);
            }
          });
        }
      }
      /**
       * 
       * 删除审核员
       * 
       * **/
      function handleDelete(item) {
        if (refundType.value == "scenic_spot") {
          // 景区
          ScenicApi.delScenicRefundUser({
            s_id: currentRow.value.s_id,
            u_uuid: item.u_uuid,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("移除审核人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "移除审核人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "store") {
          // 门店
          StoreApi.delPomRefundUser({
            pom_id: currentRow.value.pom_id,
            u_uuid: item.u_uuid,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("移除核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "移除核验人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "hotel") {
          // 酒店
          HotelApi.delHotelRefundUser({
            h_id: currentRow.value.h_id,
            u_uuid: item.u_uuid,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("移除核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "移除核验人员失败！";
              ElMessage.error(msg);
            }
          });
        } else if (refundType.value == "member") {
          // 会员
          VipApi.delActiveRefundUser({
            ma_id: currentRow.value.ma_id,
            u_uuid: item.u_uuid,
          }).then((res) => {
            if (res.Code === 200) {
              ElMessage.success("移除核销人员成功！");
              getDetail();
            } else {
              let msg = res.Message ? res.Message : "移除核验人员失败！";
              ElMessage.error(msg);
            }
          });
        }
      }
      return {
        openDialog,
        dialogRef,
        currentRow,
        currentId,
        closeDialog,
        authData,
        refundType,
        getUserOptions,
        tableTitle,
        userId,
        options,
        handleAdd,
        peopleInfo,
        handleDelete,
        operatorInfo,
      };
    },
  };
</script>

<style lang="scss">
  .checker-dialog {
    .el-dialog__body {
      height: 600px;
    }

    .left-title {
      color: var(--text-color);
      margin-bottom: 10px;
    }

    .add-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      .right-search {
        display: flex;

        .el-select {
          width: 300px;
        }

        .add-btn {
          background-color: var(--theme-color);
          color: #fff;
          cursor: pointer;
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .people-info {
      display: flex;
      justify-content: space-between;
      background-color: var(--theme-bg-color);
      padding: 10px 20px;
      border-radius: 4px;
      margin-top: 10px;

      .basic-info {
        >span {
          padding-right: 24px;
        }
      }

      .delete-action {
        display: flex;

        .operator {
          color: #cccccc;
        }

        .delete-btn {
          padding-left: 24px;
          color: var(--theme-color);
          cursor: pointer;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>