const diningChilds = [
  {
    path: "dconfig",
    name: "Dconfig",
    component: () => import("@/views/dining/dconfig.vue"),
    children: [
      {
        path: "dclist",
        name: "Dclist",
        component: () => import("@/views/dining/children/dclist.vue"),
      },
      // 商品分类
      {
        path: "produClassif",
        name: "ProduClassif",
        component: () => import("@/views/dining/children/produClassif.vue"),
      },
      // 门店商品
      {
        path: "storeProducts",
        name: "StoreProducts",
        component: () => import("@/views/dining/children/storeProducts.vue"),
      },
    ]
  },
  // 会员管理
  {
    path: "dmanage",
    name: "Dmanage",
    component: () => import("@/views/dining/dmanage.vue"),
    children: [
      {
        path: "dmember",
        name: "Dmember",
        component: () => import("@/views/dining/children/dmember.vue"),
      }
    ]
  },
  {
    path: "dorders",
    name: "Dorders",
    component: () => import("@/views/dining/dorders.vue"),
    children: [
      // 餐饮订单
      {
        path: "cateringOrders",
        name: "CateringOrders",
        component: () => import("@/views/dining/children/cateringOrders.vue"),
      },
    ]
  },
  {
    path: "dkitchen",
    name: "Dkitchen",
    component: () => import("@/views/dining/dkitchen.vue"),
    children: [
      // 后厨制作
      {
        path: "kitchenPrepar",
        name: "KitchenPrepar",
        component: () => import("@/views/dining/children/kitchenPrepar.vue"),
      },
    ]
  },
];
export default diningChilds;
