<!-- 标题 -->

<template>
  <div class="w-title">
    <div class="title_name">
      <div class="divider"></div>
      <div>{{ title_name }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: [],
    components: {},
    props: {
      title_name: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {

      return {

      };
    },
  };
</script>

<style lang="scss" scope>
  .w-title {
    margin: 10px 0;

    .title_name {
      display: flex;
      font-size: 16px;
      font-weight: bold;
    }

    .divider {
      width: 3px;
      height: 12px;
      border-radius: 2px;
      background-color: var(--theme-color);
      margin-right: 5px;
      margin-top: 4px;
    }
  }
</style>