const scenicChilds = [
  {
    path: "stickets",
    name: "Stickets",
    component: () => import("@/views/scenic/stickets.vue"),
    children: [
      {
        path: "stmanage",
        name: "Stmanage",
        component: () => import("@/views/scenic/children/stmanage.vue"),
      },
    ],
  },
  {
    path: "sale",
    name: "Sale",
    component: () => import("@/views/scenic/sale.vue"),
    children: [
      {
        path: "olsale",
        name: "Olsale",
        component: () => import("@/views/scenic/children/olsale.vue"),
      },
      {
        path: "aborigines",
        name: "Aborigines",
        component: () => import("@/views/scenic/children/aborigines.vue"),
      },
    ],
  },
  {
    path: "sconfig",
    name: "Sconfig",
    component: () => import("@/views/scenic/sconfig.vue"),
    children: [
      {
        path: "secnf",
        name: "Secnf",
        component: () => import("@/views/scenic/children/secnf.vue"),
      },
    ],
  },
  {
    path: "museum",
    name: "Museum",
    component: () => import("@/views/scenic/museum.vue"),
    children: [
      {
        path: "file",
        name: "File",
        component: () => import("@/views/scenic/children/FileManagement.vue"),
      },
      {
        path: "point",
        name: "Point",
        component: () => import("@/views/scenic/children/PointManagement.vue"),
      }
    ],
  },
  // 会员管理
  {
    path: "smanage",
    name: "Smanage",
    component: () => import("@/views/scenic/smanage.vue"),
    children: [
      {
        path: "smember",
        name: "Smember",
        component: () => import("@/views/scenic/children/smember.vue"),
      }
    ]
  },
];
export default scenicChilds;
